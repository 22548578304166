import { combineReducers } from "redux"
import { SHOW_REQUEST_ACCESS_WINDOW } from "./actionTypes"

const showRequestAccessWindow = (state = false, action) => {
  switch (action.type) {
    case SHOW_REQUEST_ACCESS_WINDOW:
      return action.showStatus
    default:
      return state
  }
}

const ResultReducer = combineReducers({ showRequestAccessWindow })

export default ResultReducer
